import React from 'react';

import frontendImage1 from 'assets/images/pages/diensten_frontenddesign/1.jpg';
import frontendImage2 from 'assets/images/pages/diensten_frontenddesign/2.jpg';
import frontendImage3 from 'assets/images/pages/diensten_frontenddesign/3.png';

import Title from 'blocks/Title';
import Image from 'blocks/Image';
import Content from 'blocks/Content';
import ContactCta from 'blocks/ContactCta';

import Layout from 'components/Layout';
import Seo from 'components/Seo';
import Section from 'components/Section';
import Heading from 'components/Heading';
import Text from 'components/Text';
import Quote from 'components/Quote';
import Button from 'components/Button';

const DienstenOntwerpFrontend = () => (
  <Layout>
    <Seo image={{ src: frontendImage1 }} />

    <Title
      image={{ src: frontendImage1 }}
      alt="Doelgericht dankzij de front-end designer van Batao"
      subtitle="Ons ontwerp, jullie uitstraling"
      title="Doelgericht dankzij de front-end designer van Batao"
    >
      Als full-service E-commerce bureau kunnen we je namelijk helpen bij de
      ontwikkeling van je volledige E-commerce platform.
    </Title>

    <Section>
      <Image
        image={{ src: frontendImage2 }}
        alt="Wij leren jouw organisatie graag kennen"
      >
        <Heading size="xl" as="h2">
          Wij leren jouw organisatie graag kennen
        </Heading>

        <Text>
          Het logo en de huisstijl staan (daar kunnen we je overigens ook bij
          helpen!), je weet wat je als bedrijf uit wil stralen maar nu moet dit
          nog in een mal worden gegoten door een goede front-end designer? Dat
          is mooi, want dat is precies wat iedere front-end designer van Batao
          doet. Dat, en veel méér! Als full-service E-commerce bureau kunnen we
          je namelijk helpen bij de ontwikkeling van je volledige E-commerce
          platform.
        </Text>
      </Image>
    </Section>

    <Section>
      <Content wide>
        <Quote author="Leroy Nas" position="Front-end developer Batao">
          “Wij vinden het belangrijk dat het ontwerp van een webshop niet alleen
          doel- en conversiegericht is en een goede user experience heeft, maar
          ook dat het design uitstraalt wat het moet uitstralen. Pas wanneer je
          een organisatie echt goed leert kennen, ben je als ontwerper in staat
          de juiste snaar te raken.”
        </Quote>
      </Content>
    </Section>

    <Section>
      <Image
        image={{ src: frontendImage3 }}
        alt="Sterke samenwerking tussen core-designer and front-end designer"
        position="right"
      >
        <Heading size="xl" as="h2">
          Sterke samenwerking tussen core-designer and front-end designer
        </Heading>

        <Text spacing="lg">
          Waar de designer denkt aan zaken als de artistieke kwaliteit van het
          ontwerp, denkt de front-end designer meer vanuit het daadwerkelijk
          kunnen inzetten van het ontwerp: vormen, balans en symmetrie. Doordat
          zij altijd nauw samenwerken met elkaar en de opdrachtgever, garanderen
          we dat we komen tot een geweldig ontwerp voor jouw merk. Bekijk
          hieronder welke diensten wij jou aan kunnen bieden. Wil je een webshop
          laten ontwerpen en ben je benieuwd wat de mogelijkheden zijn?
        </Text>

        <Button color="tertiary" to="/contact">
          Neem contact met ons op
        </Button>
      </Image>
    </Section>

    <ContactCta />
  </Layout>
);

export default DienstenOntwerpFrontend;
